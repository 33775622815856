<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>代理商管理</p>
			<i>/</i>
			<p>代理商列表</p>
		</nav>

		<div class="head_search_wrap">

			<div class="search_one_line">
				<div class="left">
					<el-form label-width="120px">
						<el-form-item label="代理商名称:">
							<el-input class="input_medi" v-model="keyword" placeholder="请输入代理商名称"></el-input>
						</el-form-item>
						<el-form-item label="状态：">
							<el-select v-model="type" placeholder="全部状态">
								<el-option label="激活" value="1"></el-option>
								<el-option label="冻结" value="0"></el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</div>
				<div class="right">
					<p class="reset" @click="resetFn">重置</p>
					<p class="search" @click="searchFn">查询</p>
				</div>
			</div>

		</div>

		<div class="page_table">
			<div class="page_table_title">
				<div class="left">
					<p>代理商列表</p>
				</div>
				<div class="right">
					<span class="span1" @click="addFn">新增代理商</span>
				</div>
			</div>
			<el-table :data="listData" class="tb-edit" style="width: 100%" highlight-current-row>
				<el-table-column prop="user_sn" label="代理商编码" align="left"></el-table-column>
				<el-table-column prop="agent_name" label="代理商名称" align="center"></el-table-column>
				<el-table-column prop="role_name" label="代理商角色" align="center"></el-table-column>
				<el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
				<el-table-column prop="contactor" label="联系人" align="center"></el-table-column>
				<el-table-column prop="useful_life" label="开通年限" align="center"></el-table-column>
				<el-table-column prop="" label="已发展企业数" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.developed_custom > 0">
							<p>{{scope.row.developed_custom}}</p>
						</div>
						<div v-else>
							<p>--</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="start_time" label="生效日期" align="center"></el-table-column>
				<el-table-column prop="end_time" label="到期日期" align="center"></el-table-column>
				<el-table-column prop="" label="状态" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.status == '1'">
							<p class="is_sale_1">激活</p>
						</div>
						<div v-else>
							<p class="is_sale_0">冻结</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="160px">
					<template slot-scope="scope">
						<div class="operation">
							<span @click="detailsFn(scope.row)">详情</span>
							<span @click="modifyFn(scope.row)">修改</span>
							<span @click="renewFn(scope.row)">续费</span>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="block">
				<el-pagination @current-change="currentChange" :current-page="currentPage1" :total="total">
				</el-pagination>
			</div>

		</div>



	</div>
</template>

<script>
	export default {
		name: 'agentList',
		data() {
			return {
				listData: [], // 列表数据
				total: 0, //总页数
				currentPage1: 1, //当前页码
				keyword: '', // 关键字搜索
				type: '',  // 状态
			}
		},
		components: {

		},
		created() {
			// 获取代理商列表
			this.getAgenList();
		},
		methods: {
			// 重置
			resetFn() {
				this.currentPage1 = 1;
				this.keyword = '';
				this.type = '';
				this.getAgenList();
			},

			// 搜索
			searchFn() {
				this.currentPage1 = 1;
				this.getAgenList();
			},
			
			// 获取代理商列表
			getAgenList(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}agent/list`,
					data: {
						page: this.currentPage1,
						agent_name: this.keyword,
						status: this.type,
					}
				}).then( res => {
					if(res.code == '0'){
						this.total = res.count;
						this.listData = res.data;
					}
				})
			},

			// 分页器函数
			currentChange(val) {
				this.currentPage1 = val;
				this.getAgenList();
			},

			// 新增
			addFn() {
				this.$router.push('/agentEdit');
			},

			// 修改
			modifyFn(row) {
				this.$router.push({
					path: '/agentEdit',
					query:{
						id: row.id,
					}
				})
			},

			// 详情
			detailsFn(row) {
				this.$router.push({
					path: '/agentInfo',
					query:{
						id: row.id
					}
				});
			},
			
			// 续费
			renewFn(row){
				this.$router.push({
					path: '/agenRenewEdit',
					query: {
						agent_id: row.id,
						name: row.role_name,
					}
				})
			},
			
		}
	}
</script>
<style scoped lang="less">
	.operation {
		span {
			padding: 8px;
			font-size: 14px;
			color: #EE7B1D;
			cursor: pointer;
		}
	}
</style>
